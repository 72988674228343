/* eslint-disable max-len */

import type { ApiLangPack } from '../api/types';

export const fallbackLangPackInitial = {
  WrongNumber: '錯誤的電話號碼?',
  SentAppCode: '我們已將驗證碼發送到您其他設備上的Telegram應用程序',
  'Login.JustSentSms': '我們已向您的手機發送了驗證碼',
  'Login.Header.Password': '輸入你的密碼',
  'Login.EnterPasswordDescription': '您啓用了兩步驗證，因此您的帳戶受到額外密碼的保護.',
  StartText: '請確認您的國家及手機號',
  'Login.PhonePlaceholder': '電話號碼',
  'Login.Next': '下一步',
  'Login.QR.Login': '二維碼登錄',
  'Login.QR.Title': '二維碼登錄',
  'Login.QR.Help1': '打開你的手機Telegarm',
  'Login.QR.Help2': '轉到 **設置** > **設備** > **鏈接桌面設備**',
  'Login.QR2.Help2': '轉到 **設置**  → **設備** → **鏈接桌面設備**',
  'Login.QR.Help3': '將手機指向此屏幕以確認登錄',
  'Login.QR.Cancel': '通過電話號碼登錄',
  YourName: '你的名字',
  'Login.Register.Desc': 'Enter your name and add a profile picture.',
  'Login.Register.FirstName.Placeholder': '姓',
  'Login.Register.LastName.Placeholder': '名',
  'Login.SelectCountry.Title': '國家',
  lng_country_none: 'Country not found',
  PleaseEnterPassword: '輸入你的新密碼',
  PHONE_NUMBER_INVALID: '電話號碼錯誤',
  PHONE_CODE_INVALID: '驗證碼錯誤',
  PASSWORD_HASH_INVALID: '密碼錯誤',
  PHONE_PASSWORD_FLOOD: '超出限制，請稍後重試',
  PHONE_NUMBER_BANNED: '電話號碼被禁止',
} as ApiLangPack;
